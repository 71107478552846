<template>
  <div class="container">
    <div class="demand-module">
      <div class="demand-details">
        <div class="dd-info">
          <div class="demand-place">
            <div class="dp-title">
              <em class="dp-tag">精选</em>
              <h3 class="title-txt">
                <span>标题：</span>
                <span>{{demand_details.theme}}</span>
              </h3>
            </div>
            <p class="dp-site">
              <span>活动地点：</span>
              <span>{{demand_details.home_province}}-{{demand_details.home_city}}</span>
            </p>
            <p class="dp-price">
              <span class="price-key">预算：</span>
              <span class="price-val">￥{{demand_details.budget}}</span>
            </p>
            <div class="dp-countdown">
              <span class="dp-people">{{demand_details.competitive_participate}}人</span>
              <span class="countdown-box">
                <my-count-down :end-time="demand_details.open_end_time_date" :minute="false" :second="false" />
                <span class="cd-item">后截止</span>
                <!-- <span class="cd-item">5</span>
                <span class="cd-item">天</span>
                <span class="cd-item">8</span>
                <span class="cd-item">小时</span>
                <span class="cd-item">后截止</span> -->
              </span>
            </div>
          </div>
          <div class="contact-way">
            <div class="base-info">
              <h3 class="bi-title">具体需求</h3>
              <div class="bi-main">
                <p class="bi-tr">
                  <span span class="bi-key">姓名：</span>
                  <span class="bi-val">{{demand_details.name}}</span>
                </p>
                <p class="bi-tr">
                  <span span class="bi-key">公司名称：</span>
                  <span class="bi-val">{{demand_details.company}}</span>
                </p>
                <p class="bi-tr">
                  <span span class="bi-key">联系电话：</span>
                  <span class="bi-val">{{demand_details.tel_secrecy}}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="dd-content">
          <div class="base-info">
            <h3 class="bi-title">基本信息</h3>
            <div class="bi-main">
              <p class="bi-tr">
                <span class="bi-key">赏金：</span>
                <span class="bi-val">￥{{demand_details.budget}}</span>
              </p>
              <p class="bi-tr">
                <span class="bi-key">招标范围：</span>
                <span class="bi-val">{{demand_details.competitive_scope}}</span>
              </p>
              <p class="bi-tr">
                <span class="bi-key">招标天数：</span>
                <span class="bi-val">{{demand_details.open_day}}</span>
              </p>
              <p class="bi-tr">
                <span class="bi-key">投标商数量：</span>
                <span class="bi-val">{{demand_details.competitive_number}}（还剩{{demand_details.competitive_surplus}}个名额）</span>
              </p>
              <p class="bi-tr">
                <span class="bi-key">活动预算：</span>
                <span class="bi-val">￥{{demand_details.budget}}</span>
              </p>
              <p class="bi-tr">
                <span class="bi-key">活动时间：</span>
                <span class="bi-val">{{demand_details.demand_time}}</span>
              </p>
              <p class="bi-tr">
                <span class="bi-key">活动天数：</span>
                <span class="bi-val">{{demand_details.day}}天</span>
              </p>
              <p class="bi-tr">
                <span class="bi-key">活动人数：</span>
                <span class="bi-val">{{demand_details.number}}人</span>
              </p>
              <p class="bi-tr">
                <span class="bi-key">活动地点：</span>
                <span class="bi-val">{{demand_details.home_province }}-{{demand_details.home_city }}</span>
              </p>
            </div>
          </div>
          <div class="base-info">
            <h3 class="bi-title">具体需求</h3>
            <div class="bi-main">
              <p class="bi-tr">
                <span class="bi-val">{{demand_details.describe}}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="demand-form">
        <div class="fm-rest" v-if="!is_bid">
          <div class="fm-confirm">
            <!-- <el-tooltip effect="dark" content="" placement="top">
              <div slot="content">
                <p>不可竞标原因</p>
              </div> -->
              <el-button
                class="fake-btn"
                @click="bidBtnClickFun"
              >
                <span>投标</span>
              </el-button>
            <!-- </el-tooltip> -->
          </div>
        </div>
        <bid-temp :id="data_id" v-else />
      </div>
    </div>



    <!-- 对话框 -->
    <my-dialog :visible="myDialog.visible" :options="myDialog.options" @on-change="myDialogChangeFun" />

  </div>
</template>

<script>
import bidTemp from '@/views/demand/components/bid-temp.vue';
import myCountDown from '@/components/my-count-down/MyCountDown.vue';
export default {
  // 允许组件模板递归地调用自身
  name: 'DemandDetails',
  // 声明一组可用于组件实例中的组件
  components: {
    bidTemp,
    myCountDown,
  },
  // 一个用于从父组件接收数据的数组或对象
  props: {

  },
  // 该函数返回组件实例的 data 对象
  data () {
    return {
      data_id: '', // 数据id
      demand_details: [], // 详情数据
      is_bid: false, // 是否投标
      myDialog: {
        data: {},
        visible: false,
        options: {},
      },
      login_info: null,
    }
  },
  // 计算属性：
  computed: {

  },
  // 钩子函数--侦听data变动：
  watch: {

  },
  // 在实例创建完成后被立即同步调用
  methods: {
    /**
     * 获取页面数据
     */
    getPageDataFun() {
      // 获取当前登录账号信息
      this.getCurrentLoginAccountInfoFun();
      // 获取-id
      this.getPageIdFun().then((id) => {
        this.data_id = id;
        // 获取竞标数据
        this.getDemandDetailsDataFun();
      });
    },
    /**
     * 获取当前登录账号信息
     */
    getCurrentLoginAccountInfoFun() {
      // 登录缓存-信息
      this.login_info = this.myGetStorage(this.STORAGE.login_info);
      console.log('登录缓存-信息 this.login_info == ',this.login_info)
    },
    /**
     * 获取-id
     */
    getPageIdFun() {
      return new Promise((resolve,reject) => {
        this.myGetRouter().then((res) => {
          console.log('获取-id == ',res)
          let { id } = res.query;
          if(id || parseInt(id) == parseInt(0)){
            resolve(id);
          } else {
            throw new Error('Error: fail to get id')
            reject();
          }
        })
      })
    },
    /**
     * 获取竞标详情数据
     */
    getDemandDetailsDataFun() {
      console.log('this.data_id == ',this.data_id)
      this.myRequest({
        url: '/api/demand/details',
        data: {
          id: this.data_id,
        }
      }).then((res) => {
        console.log('获取竞标数据 res == ',res);
        let resData = res.data.data;
        this.demand_details = resData;
      }).catch((err) => {
        // console.log('获取竞标数据 err == ',err);
      })
    },
    /**
     * 竞标开关
     */
    bidSwitchFun() {
      this.is_bid = !this.is_bid;
    },
    /**
     * 竞标按钮点击
     */
    bidBtnClickFun() {

      let { login_info, demand_details } = this;

      //验证是否参与自己发布的需求
      if(login_info.id == demand_details.user_id){
        this.myMessage({
          message: '不可参与自己发布的需求'
        })
        return
      }
      //验证是否是重复竞标需求
      if(demand_details.is_participate == 1){
        this.myMessage({
          message: '您已参与过该竞标，不可重复参与'
        })
        return
      }

      // 验证用户是否可以参与
      this.varifyUserWhetherApplyFun().then((res) => {
        console.log('验证用户是否可以参与 res == ',res);
        let resData = res.data.data;
        let status = resData.operation_status;
        let result = {
          title: '',
          message: '',
        };
        // let skip_url = '/identification?is_pass=true';
        // 操作状态 【0可正常参与竞标，1不是企业不可以参与竞标，2免费竞标次数已用完，3竞标区域限制vip等级不够】
        switch(+status) {
          case 0: // 0可正常参与竞标
            // 竞标开关
            this.bidSwitchFun()
          break;

          case 1: // 1不是企业不可以参与竞标
            console.log('企业')
            this.tipsWinowShowFun({
              title: '参与失败',
              message: '不是企业用户不可以参与竞标，请认证企业用户。',
              url: '/identification?current=2&is_pass=true',
              confirm_text: '认证',
            });
          break;

          case 2: // 2免费竞标次数已用完
            console.log('免费次数')
            this.tipsWinowShowFun({
              title: '参与失败',
              message: '免费竞标次数已用完，请开通会员享受更好的服务。',
              url: '/vip',
              confirm_text: '开通',
            });
          break;

          case 3: // 3竞标区域限制vip等级不够
            console.log('vip等级不够')
            this.tipsWinowShowFun({
              title: '参与失败',
              message: '您的权限不够，请升级会员享受更全面的服务。',
              url: '/vip',
              confirm_text: '升级',
            });
          break;

          case -1: // -1其他
            console.log('其他')
            this.tipsWinowShowFun({
              title: '参与失败',
              message: '您的权限不够，请升级会员享受更全面的服务。',
              url: '/vip',
              confirm_text: '升级',
            });
          break;

          default:// 默认0 可正常参与竞标
            // 竞标开关
            this.bidSwitchFun()
          break;
        }
      });
    },
    /**
     * 验证用户是否可以参与
     */
    varifyUserWhetherApplyFun() {
      return this.myRequest({
        url: '/api/demand/schemeallow',
        data: {
          id: this.data_id,//需求id
        },
        errorHint: false,
      })
      // .then((res) => {
      //   console.log('验证用户是否可以参与 res == ',res);
      //   let resData = res.data.data;
      // })
      // .catch((err) => {
      //   console.log('验证用户是否可以参与 err == ',err);
      // })

    },
        /**
     * 显示提示弹窗
     */
    tipsWinowShowFun(opt) {

      let defaultConfig = {
        is_title: false, // 是否显示标题
        is_tips: false,
        title: '参与失败',
        message: '免费竞标次数已用完，请开通会员享受更全面的服务。',
        url: '/identification',
        confirm_text: '开通',
      }

      // 参数覆盖
      defaultConfig = Object.assign(defaultConfig,opt);

      console.log('defaultConfig == ',defaultConfig)

      this.myDialog = {
        data: {
          url: defaultConfig.url,
        },
        visible: true,
        options: {
          is_title: defaultConfig.is_title, // 是否显示标题
          is_tips: defaultConfig.is_tips,
          title: defaultConfig.title,
          message: defaultConfig.message,
          confirm_text: defaultConfig.confirm_text,
        },
      }
    },
    /**
     * 初始化对话框
     */
    initMyDialogFun() {
      this.myDialog = {
        data: {},
        visible: false,
        options: {},
      }
    },
    /**
     * 对话框回调
     */
    myDialogChangeFun(e) {
      console.log('对话框回调 e == ',e);
      let { visible, status, message, data, name, sign } = e;
      if(status == 1) { // 确认
        let { url } = this.myDialog.data;
        // 跳转路由
        this.myRouterPush({
          path: url,
          query: {
            // data: encodeURIComponent(JSON.stringify(skip_qurey)),
          }
        });
      } else { // 取消
        // 初始化对话框
        this.initMyDialogFun();
        // this.$router.go(-1);
      }
    },
  },
  // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
  created() {

  },
  // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
  mounted() {
    // 获取页面数据
    this.getPageDataFun();
  },
  beforeCreate() { // 创建前

  },
  beforeMount() { // 挂载前

  },
  beforeUpdate() { // 更新前

  },
  updated() { // 更新后

  },
  beforeDestroy() { // 销毁前

  },
  destroyed() { // 销毁后

  },
  activated() { // 缓存保持，需要由 keep-alive 触发

  },
}
</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
.container {
  box-sizing: border-box;
}
.demand-module {
  box-sizing: border-box;
  padding: 0 $theme-widht-whiteedge;
  margin: 50px auto;
  // width: $theme-view-widht;
  max-width: $theme-view-widht;
  min-width: $theme-view-min-widht;
  .demand-details {
    $float-width: 300px;
    $subsidy: 10px;
    $box-shadow: 0 0 9px -4px rgba(0,0,0,0.6);
    position: relative;
    box-sizing: border-box;
    // padding-left: $float-width;
    .dd-info {
      box-sizing: border-box;
      padding: 30px;
      box-shadow: $box-shadow;
      width: calc(#{$float-width} + #{$subsidy});
      position: relative;
      z-index: 2;
      background: #fff;
      .demand-place {
        $rowGutter: 10px;
        .dp-title {
          .dp-tag {
            position: relative;
            top: -2px;
            box-sizing: border-box;
            padding: 4px 8px;
            margin-right: 16px;
            font-size: 12px;
            color: #FFFFFF;
            background: $common-number1-color;
          }
          .title-txt {
            display: inline;
            line-height: 1.7;
            font-size: 18px;
            color: #444343;
          }
        }
        .dp-site {
          margin-top: $rowGutter;
          font-size: 14px;
          color: #787878;
        }
        .dp-price {
          margin-top: $rowGutter;
          .price-key {
            font-size: 14px;
            color: #787878;
          }
          .price-val {
            font-size: 18px;
            color: #E62129;
          }
        }
        .dp-countdown {
          display: inline-block;
          box-sizing: border-box;
          padding: 4px 8px;
          margin-top: $rowGutter;
          font-size: 12px;
          color: #444343;
          border: 1px solid $common-number1-color;
          .dp-people {
            margin-right: 8px;
          }
          .countdown-box {
            .cd-item {}
          }
        }
      }
      .contact-way {
        margin-top: 40px;
        .base-info {
          // float: left;
          box-sizing: border-box;
          .bi-title {
            font-size: 16px;
            color: #444343;
          }
          .bi-main {
            .bi-tr {
              margin-top: 10px;
              font-size: 14px;
              color: #787878;
            }
          }
        }
      }
    }
    .dd-content {
      box-shadow: $box-shadow;
      box-sizing: border-box;
      padding: 17px 0;
      // padding-left: $subsidy;
      background: #F4F4F4;
      min-height: 317px;
      position: absolute;
      top: 10px;
      left: 0;
      bottom: 10px;
      width: 100%;
      padding-left: 280px;

      &::after {
        content: '';
        display: block;
        clear: both;
      }
      .base-info {
        float: left;
        box-sizing: border-box;
        padding: 0 50px;
        width: 50%;
        .bi-title {
          font-size: 16px;
          color: #444343;
        }
        .bi-main {
          .bi-tr {
            margin-top: 10px;
            font-size: 14px;
            color: #787878;
          }
        }
      }
    }
  }
  .demand-form {
    margin: 50px 0;
    .fm-confirm {
      width: 200px;
      margin: 0 auto;
      .fake-btn {
        width: 100%;
        font-size: 14px;
        color: #fff;
        border: 1px solid $common-number1-color;
        background: $common-number1-color;
        outline: none;
        &.el-button:hover,&.el-button:focus,&.el-button:active {
          color: none;
          border-color: none;
          background-color: none;
        }
        &.gray-style {
          border: 1px solid #808080;
          background: #808080;
        }
      }
    }
  }
}
</style>
