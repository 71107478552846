<template>
  <div class="bid-container">
    <div class="form-title-box">
      <form-title title="投标信息填写" descriptions="Bidding" text="information" />
    </div>
    <div class="form-module flex-style">
      <div class="fm-itme">
        <div class="fm-key">
          <div class="key-title required-style">报价：</div>
        </div>
        <div class="fm-val">
          <div class="field-item">
            <div class="input-box">
              <el-input v-model="formData.price" placeholder="￥请输入报价额度" @input="inputChangeFun($event,'price')">
              </el-input>
            </div>
          </div>
        </div>
      </div>
      <div class="fm-itme">
        <div class="fm-key">
          <div class="key-title required-style">地点：</div>
        </div>
        <div class="fm-val">
          <div class="field-item">
            <div class="select-city"
              :class="{'active-one': formData.province,'active-two': formData.city,'active-three': formData.county}">
              <v-distpicker :province="formData.province" :city="formData.city" hide-area
                @province="function(e) { formData.province = e.value; formData.city = null; }"
                @city="function(e) { formData.city = e.value;}" :placeholders="{ province: '省', city: '市', area: '区' }">
              </v-distpicker>
            </div>
          </div>
        </div>
      </div>
      <div class="fm-itme">
        <div class="fm-key">
          <div class="key-title required-style">姓名：</div>
        </div>
        <div class="fm-val">
          <div class="field-item">
            <div class="input-box">
              <el-input v-model="formData.name" placeholder="请输入姓名" @input="inputChangeFun($event,'name')"></el-input>
            </div>
          </div>
        </div>
      </div>
      <div class="fm-itme">
        <div class="fm-key">
          <div class="key-title required-style">联系方式：</div>
        </div>
        <div class="fm-val">
          <div class="field-item">
            <div class="input-box">
              <el-input maxlength="11" v-model="formData.tel" placeholder="请输入联系方式"
                @input="inputChangeFun($event,'tel')"></el-input>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-module">
      <div class="fm-itme">
        <div class="fm-key">
          <div class="key-title required-style">方案标题：</div>
        </div>
        <div class="fm-val">
          <div class="field-item">
            <div class="input-box">
              <el-input v-model="formData.content_title" placeholder="请输入方案标题"
                @input="inputChangeFun($event,'content_title')"></el-input>
            </div>
          </div>
        </div>
      </div>
      <div class="fm-itme">
        <div class="fm-key">
          <div class="key-title required-style">方案介绍：</div>
        </div>
        <div class="fm-val">
          <div class="field-item">
            <div class="input-box textarea-style">
              <el-input type="textarea" :rows="3" v-model="formData.content_msg" placeholder="请输入方案介绍"
                @input="inputChangeFun($event,'content_msg')">
              </el-input>
              <div class="limit-to">{{formData.content_msg.length}}/200</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-module">
      <div class="fm-itme">
        <div class="fm-val">
          <div class="field-item upload-field-box">
            <div class="upload-module">
              <el-upload class="upload-demo" ref="upload1" :action="ossBaseUrl" :limit="2" :name="upload_option.name"
                :data="upload_option.param" :before-upload="handleBeforeUploadFun" :on-progress="handleProgressFun"
                :on-success="handleSuccessFun" :on-error="handleErrorFun" :on-preview="handlePreviewFun"
                :on-remove="function(file, fileList){return handleRemoveFun(file, fileList,'upload1');}"
                :on-change="function(file, fileList){ fileList = []; formData.content = []; return handleChangeFun(file, fileList,'upload1');}"
                :auto-upload="false" :file-list="fileList">
                <el-button class="fake-btn" slot="trigger" size="small" type="primary">
                  <div class="btn-main">
                    <i class="iconfont icon-shangchuan"></i>
                    <span>上传方案</span>
                  </div>
                </el-button>
                <!-- <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器</el-button> -->
              </el-upload>
            </div>
            <div class="upload-tips">
              <span class="fake-span">注：</span>
              <span class="fake-span">投标需要缴纳投标金200元放于平台，竞标结束后无论中标与否都会将押金退还。提交成功后可在佰团乐小程序-我的-我的交易中查看您的竞标详情</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-module">
      <div class="fm-rest">
        <div class="fm-confirm">
          <el-button class="fake-btn" @click="clickSubmitBtnFun">
            <span>提交</span>
          </el-button>
        </div>
        <!-- <div class="fm-tips">发布成功后可在同步手机-我的-发布管理中查看您的发布详情</div> -->
      </div>
    </div>

    <!-- 支付弹窗 -->
    <!-- <my-pay-dialog
      :visible="pay_config.visible"
      :payee="pay_config.payee"
      :price="pay_config.price"
      :qr-code="pay_config.qr_code"
      @on-change="payResultChangeFun"
    /> -->

    <!-- 支付弹窗 -->
    <my-pay-dialog :visible="pay_config.visible" :payee="pay_config.payee" :price="pay_config.price"
      :qr-code="pay_config.qr_code" :pay-mode="pay_config.select.pay_mode" @on-change="payResultChangeFun" />

    <!-- 选址支付方式 -->
    <select-pay-method :visible="pay_config.select.visible" @on-change="choosePayResultChangeFun" />

  </div>
</template>

<script>
  import VDistpicker from 'v-distpicker';
  import formTitle from '@/components/form-title/FormTitle.vue';
  import {
    dataURLToBlob, // 将base64转换为blob
    fileToBase64, // file转base64
  } from '@/utils/utils_fun.js'
  export default {
    // 允许组件模板递归地调用自身
    name: '',
    // 声明一组可用于组件实例中的组件
    components: {
      VDistpicker,
      formTitle
    },
    // 一个用于从父组件接收数据的数组或对象
    props: {
      id: null
    },
    // 该函数返回组件实例的 data 对象
    data() {
      return {
        ossBaseUrl: this.GLOBAL.ossBaseUrl,
        ossUrl: this.GLOBAL.ossUrl,
        pay_config: { // 支付配置
          select: {
            metadata: {},
            visible: false,
            value: '',
            pay_mode: '',
          },
          visible: false,
          pay_timer: null,
          payee: '',
          price: '',
          qr_code: '',
        },
        fileList: [],
        formData: { // 表单数据
          id: this.id, //	string	需求id
          price: '', //	string	报价
          province: '', //	string	所在省
          city: '', //	string	所在市
          content: [], //	array	方案
          content_title: '', //	string	方案标题
          content_msg: '', //	string	方案介绍
          name: '', //	string	姓名
          tel: '', //	string	联系方式（电话）
        },
        // 上传文件配置选项
        upload_option: {
          url: '',
          name: 'file',
          param: {
            key: '',
            policy: '',
            OSSAccessKeyId: '',
            signature: '',
          },
          record_info: {
            name: '',
            url: '',
          }
        }
      }
    },
    // 计算属性：
    computed: {

    },
    // 钩子函数--侦听data变动：
    watch: {
      id: function (e) {
        this.formData.id = e;
      }
    },
    // 在实例创建完成后被立即同步调用
    methods: {
      // 初始化页面 data 数据
      initPageDataFun() {
        this.pay_config = { // 支付配置
          select: {
            metadata: {},
            visible: false,
            value: '',
            pay_mode: '',
          },
          visible: false,
          pay_timer: null,
          payee: '',
          price: '',
          qr_code: '',
        };
        this.formData = { // 表单数据
          id: this.id, //	string	需求id
          price: '', //	string	报价
          province: '', //	string	所在省
          city: '', //	string	所在市
          content: '', //	array	方案
          content_title: '', //	string	方案标题
          content_msg: '', //	string	方案介绍
          name: '', //	string	姓名
          tel: '', //	string	联系方式（电话）
        };
      },
      /**
       * 将文件提交到服务器
       * $refs：vue 语句，配合 ref="upload" 属性获取节点对象；
       * @parma file {Object}
       */
      mySubmitUploadFileFun(file) {
        console.log('将文件提交到服务器 == ', file)
        const _this = this;
        return new Promise((resolve, reject) => {

          if (file && file.status == 'ready') {
            // let { type } = file.raw;

            // 获取oss签名
            this.myGetOssSignature(file).then((res) => {
              console.log('oss 签名回调 == ', res);
              let {
                path,
                key,
                policy,
                OSSAccessKeyId,
                accessid,
                signature
              } = res;
              let param = {
                key,
                policy,
                OSSAccessKeyId,
                signature,
              };
              this.upload_option.param = param;
              console.log('this.upload_option == ', this.upload_option)
              this.myUploadFileToServers(param, file).then((res) => {
                resolve(path);
              });
              // setTimeout(()=>{
              //   // 执行节点对象函数
              //   _this.$refs.upload.submit();
              // },3000)
            })
          } else {
            throw (new Error('Error: There are no committable objects!'))
            reject();
          }
        })
      },
      /**
       * 文件状态改变时-的钩子函数
       */
      handleChangeFun(file, fileList, name) {
        console.log('文件状态改变时 file == ', file);
        console.log('文件状态改变时 fileList == ', fileList);
        console.log('文件状态改变时 name == ', name);
        console.log('文件状态改变时 this.fileList == ', this.fileList);
        // 删除文件
        // this.deleteFileFun();
        let flag = this.handleBeforeUploadFun(file);
        console.log('文件状态改变时 flag == ', flag);
        if (!flag) {
          this.fileList = [];
          return;
        }
        // 将文件提交到服务器
        this.mySubmitUploadFileFun(file).then((res) => {
          console.log('远程路径 == ', res)
          this.formData.content[0] = res; //	array	方案
          // this.fileList = [{name: file.name, url: res}];
          delete file.status;
          this.fileList = [file];
          console.log('将文件提交到服务器 this.formData == ', this.formData);
          console.log('将文件提交到服务器 this.fileList == ', this.fileList);
        }).catch(() => {});
      },
      /**
       * 上传文件之前-的钩子函数
       */
      handleBeforeUploadFun(file) {
        console.log('上传文件之前 file == ', file);
        const isPDF = file.raw.type === 'application/pdf';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isPDF) {
          this.$message.error('上传方案只能是 PDF 格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传方案大小不能超过 2MB!');
        }
        return isPDF && isLt2M;
      },
      /**
       * 上传文件时-的钩子函数
       */
      handleProgressFun(event, file, fileList) {
        console.log('上传文件时 event == ', event);
        console.log('上传文件时 file == ', file);
        console.log('上传文件时 fileList == ', fileList);
      },
      /**
       * 上传文件成功-的钩子函数
       */
      handleSuccessFun(response, file, fileList) {
        console.log('上传文件成功 response == ', response);
        console.log('上传文件成功 file == ', file);
        console.log('上传文件成功 fileList == ', fileList);
      },
      /**
       * 上传文件失败-的钩子函数
       */
      handleErrorFun(err, file, fileList) {
        console.log('上传文件失败 err == ', err);
        console.log('上传文件失败 file == ', file);
        console.log('上传文件失败 fileList == ', fileList);
        // 删除文件
        this.deleteFileFun();
      },
      /**
       * 移除文件-的钩子函数
       */
      handleRemoveFun(file, fileList, name) {
        console.log('移除文件 file == ', file);
        console.log('移除文件 fileList == ', fileList);
        console.log('移除文件 name == ', name);
        // 删除文件
        this.deleteFileFun();
      },
      /**
       * 删除文件
       */
      deleteFileFun() {
        let {
          content
        } = this.formData;
        if (content.length > 0) {
          content = []; //	array	方案
          this.fileList = [];
        }
      },
      /**
       * 选择预览文件-的钩子函数
       */
      handlePreviewFun(file) {
        const _this = this;
        console.log('选择预览文件 file == ', file);
        // window.open(file.url,'_blank');
        // file转base64
        fileToBase64(file.raw, function (base64) {
          if (base64 == 'data:') {
            _this.myMessage({
              message: '预览失败，或许是文件为空，请检查文件或者联系客服!'
            });
            return;
          }
          // 将base64转换为blob
          let blob = dataURLToBlob(base64);
          console.log('将base64转换为blob == ', blob);
          // blog 转 url
          let url = window.URL.createObjectURL(blob)
          console.log('将blog 转 url == ', url);
          window.open(url, '_blank');
        })
      },
      /**
       * 自定义-上传文件
       */
      myUploadFileFun(e) {
        console.log('上传文件 == ', e)
      },
      /**
       * 获取oss签名
       */
      getOssSignatureFun() {
        return new Promise((resolve, reject) => {
          // 发送请求
          this.myRequest({
            url: '/api/alioss/aliosspolicy'
          }).then(res => {
            let resData = res.data.data;
            resolve(resData);
          })
        })
      },
      /**
       * 输入框监听变化
       */
      inputChangeFun(event, name) {
        console.log(event)
        console.log(name)
        console.log(this.formData)
      },
      /**
       * 点击提交按钮
       */
      clickSubmitBtnFun() {

        // 校验数据
        let flag = this.verifyDataFun();
        if (!flag) return;

        // 选择支付-开关
        this.choosePayTaggleFun(1);

      },
      /**
       * 执行-发起录入请求-函数
       */
      executeSendEnteringRequestFun() {

        const _this = this;
        console.log('发送获取vip套餐订单请求 this.pay_config == ', this.pay_config)
        let {
          metadata,
          register
        } = this.pay_config.select;
        let {
          id: payment_id,
          name,
          payment
        } = metadata;

        // 发起录入请求
        this.sendEnteringRequestFun().then(res => {
          const _this = this;
          let {
            order_id,
            price
          } = res;
          this.pay_config.price = price;

          console.log('获取支付方式 payment_id == ', payment_id)
          // 支付押金
          this.payDepositFun(order_id, payment_id).then((res) => {
            let resData = res.data.data;

            if (res.data.code != 0) {
              this.myMessage({
                message: res.data.msg,
              });
              return;
            }

            // 如果是钱包支付
            if (payment == 'WalletPay') {
              // 支付结果回调
              _this.payResultChangeFun({
                visible: false,
                status: 1, // 1/支付成功 2/取消支付 3/取消支付，关闭弹窗
                message: '支付成功',
                data: '',
              });
            } else {

              // 循环请求
              this.myLoopRequest({
                id: resData.order_id,
                type: 'deposit_order'
              }, function (res) {
                console.log('myLoopRequest == ', res)
                let {
                  timer,
                  status,
                  data
                } = res;

                // 获取定时器
                if (!_this.pay_config.pay_timer) {
                  _this.pay_config.pay_timer = timer;
                  // 打开支付弹窗
                  _this.openPayWindowFun({
                    payee: resData.order_payee,
                    price: price,
                    qr_code: resData.pay
                  });
                }
                console.log('_this.pay_config.pay_timer == ', _this.pay_config.pay_timer)

                console.log('data.pay_status == ', data.pay_status)
                // 是否已支付
                if (data.pay_status == 1) {
                  console.log('是否已支付 == ', data.pay_status)
                  // clearInterval(_this.pay_config.pay_timer);
                  // 支付结果回调
                  _this.payResultChangeFun({
                    visible: false,
                    status: 1, // 1/支付成功 2/取消支付 3/取消支付，关闭弹窗
                    message: '支付成功',
                    data: '',
                  });
                } else if (status == 3) { // 超时
                  // 支付结果回调
                  _this.payResultChangeFun(res);
                }
              })

            }
          })
        });

      },
      /**
       * 支付押金
       */
      payDepositFun(order_id, payment_id) {
        return new Promise((resolve, reject) => {
          this.myRequest({
            method: 'post',
            url: '/api/demand/schemepay',
            data: {
              order_id: order_id, //订单id
              payment_id: payment_id, //支付方式id
            },
          }).then((res) => {
            console.log('支付押金 res == ', res);
            resolve(res);
          }).catch((err) => {
            console.log('支付押金 err == ', err)
          })
        })
      },
      /**
       * 发起录入请求
       */
      sendEnteringRequestFun() {
        console.log('发起录入请求 查看业务 this.formData == ', this.formData);
        let formData = this.formData;
        return new Promise((resolve, reject) => {
          this.myRequest({
            method: 'post',
            url: '/api/demand/scheme',
            data: formData,
          }).then((res) => {
            console.log('录入请求成功 res == ', res);
            let resData = res.data.data;
            // this.mySetStorage({ // 设置本地缓存
            //   name: 'active_test',
            //   value: this.formData,
            //   expires: 60000 * 24 * 31
            // });
            resolve(resData);
          }).catch((err) => {
            console.log('录入请求失败 err == ', err)
          })
        })

      },
      /**
       * 发起录入后
       */
      sendEnteringAfterFun() {
        // 初始化页面 data 数据
        this.initPageDataFun();
        // 跳转参数
        let skip_qurey = {
          status: '1',
          status_msg: '参与投标成功！',
          text_list: ['参与成功后可在佰团乐小程序"我的-我的交易中"查看您的竞标详情！'], // 文本
        };

        // 跳转路由
        this.myRouterPush({
          path: '/succeed',
          query: {
            data: encodeURIComponent(JSON.stringify(skip_qurey)),
          }
        });
      },
      /**
       * 校验数据
       */
      verifyDataFun() {
        let form_data = this.formData;
        console.log('form_data == ', form_data)

        //  this.formData = { // 表单数据
        //   id: this.id, //	string	需求id
        //   price: '', //	string	报价
        //   province: '', //	string	所在省
        //   city: '', //	string	所在市
        //   content: '', //	array	方案
        //   content_title: '', //	string	方案标题
        //   content_msg: '', //	string	方案介绍
        //   name: '', //	string	姓名
        //   tel: '', //	string	联系方式（电话）
        // };

        // 报价
        if (!form_data.price || !this.REGEXP.is_floating.test(form_data.price)) {
          this.myMessage({
            message: '请输入报价额度!'
          });
          return false;
        }

        // 所在地
        if (!form_data.province || form_data.province == '空' || form_data.province == '省') {
          this.myMessage({
            message: '请选择所在地'
          });
          return false;
        } else if (!form_data.city || form_data.city == '空' || form_data.city == '市') {
          this.myMessage({
            message: '请选择活动区域！'
          });
          return false;
        }

        // 姓名
        if (!form_data.name) {
          this.myMessage({
            message: '请输入姓名!'
          });
          return false;
        }

        // 联系方式
        if (!form_data.tel || !this.REGEXP.is_phone.test(form_data.tel)) {
          this.myMessage({
            message: '请输入联系方式!'
          });
          return false;
        }

        // 方案标题
        if (!form_data.content_title) {
          this.myMessage({
            message: '请输入方案标题!'
          });
          return false;
        }

        // 方案介绍
        if (!form_data.content_msg) {
          this.myMessage({
            message: '请输入方案介绍!'
          });
          return false;
        }

        // 上传方案
        if (!this.formData.content[0]) {
          this.myMessage({
            message: '请上传方案!'
          });
          return false;
        }
        return true;
      },
      /**
       * 选择支付-开关
       */
      choosePayTaggleFun(flag) {
        let result;
        switch (+flag) {
          case 1:
            result = true;
            break;
          case 2:
            result = false;
            break;
          default:
            result = !this.pay_config.select.visible;
            break;
        }
        this.pay_config.select.visible = result;
      },
      /**
       * 选择支付-结果回调
       */
      choosePayResultChangeFun(e) {
        console.log('选择支付结果回调 e == ', e)
        const _this = this;
        let {
          visible,
          status,
          message,
          data
        } = e;
        this.pay_config.select.visible = visible;
        // 是否选择支付成功
        if (status == 1) {
          let {
            id,
            name,
            payment,
          } = data
          this.pay_config.select.metadata = data;
          this.pay_config.select.value = id;
          this.pay_config.select.pay_mode = name;

          // 执行-发起录入请求-函数
          this.executeSendEnteringRequestFun();
        }
      },
      /**'
       * 打开支付弹窗
       */
      openPayWindowFun(opt) {
        this.pay_config.visible = true;
        //将obj和传进来的params合并
        this.pay_config = Object.assign(this.pay_config, opt);
        // this.pay_config.qr_code = opt.qr_code;
        // this.pay_config.price = opt.price;
        console.log('打开支付弹窗 == ', this.pay_config);
      },
      /**
       * 支付结果回调
       */
      payResultChangeFun(e) {
        console.log('支付结果回调 e == ', e)
        let {
          visible,
          status,
          message,
          data
        } = e;
        this.pay_config.visible = visible;
        // 自定义清除定时器
        this._clearIntervalFun();
        // 是否支付成功
        if (status == 1) {
          // 发起录入后
          this.sendEnteringAfterFun();
        } else {
          this.myMessage({
            message: message,
          });
        }
      },
      /**
       * 自定义清除定时器
       */
      _clearIntervalFun() {
        if (this.pay_config.pay_timer) {
          clearInterval(this.pay_config.pay_timer);
          this.pay_config.pay_timer = null;
        }
      },
    },
    // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
    created() {

    },
    // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
    mounted() {

    },
    beforeCreate() { // 创建前

    },
    beforeMount() { // 挂载前

    },
    beforeUpdate() { // 更新前

    },
    updated() { // 更新后

    },
    beforeDestroy() { // 销毁前
      // 自定义清除定时器
      this._clearIntervalFun();
    },
    destroyed() { // 销毁后

    },
    activated() { // 缓存保持，需要由 keep-alive 触发

    },
  }

</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
  .bid-container {
    box-sizing: border-box;
  }

  .form-module {
    $rowGutter: 30px;
    width: 80%;
    margin: 0 auto;

    &.flex-style {
      &::after {
        content: '';
        display: block;
        clear: both;
      }

      .fm-itme {
        width: 50%;
        height: 42px;
        float: left;
        margin: 0;
        margin-top: $rowGutter;
      }
    }

    .fm-itme {
      display: flex;
      margin: $rowGutter auto;

      .fm-key {
        box-sizing: border-box;
        min-width: 200px;
        padding-right: 16px;

        .key-title {
          display: flex;
          justify-content: flex-end;
          height: 40px;
          line-height: 40px;
          font-size: 16px;
          color: #444343;

          &.required-style {
            &::before {
              content: '*';
              position: relative;
              top: 4px;
              margin-right: 4px;
              font-size: 26px;
              color: #E62129;
            }
          }
        }
      }

      .fm-val {
        flex: 1;
        min-width: 0;

        .field-item {
          $height: 40px;
          $font-size: 14px;
          $color: #444343;

          .tips-text {
            margin-top: 6px;
            font-size: 12px;
            color: $color;
          }

          .input-box {
            position: relative;
            display: flex;
            height: $height;
            border: 1px solid #787878;
            border-radius: 4px;

            &.textarea-style {
              height: auto;
              display: block;
            }

            .input-icon {
              width: $height;
              height: $height;
              line-height: $height;
              text-align: center;
              font-size: $font-size;
              color: $color;

              .iconfont {
                font-size: 24px;
              }
            }

            .limit-to {
              z-index: 1;
              position: absolute;
              right: 10px;
              bottom: 10px;
              font-size: 14px;
              color: #AAAAAA;
            }

            &::v-deep {
              .el-input {
                height: $height;

                .el-input__inner {
                  border: none;
                  height: $height;
                  line-height: $height;
                  font-size: 14px;
                  color: #444443;
                }
              }

              .el-textarea {
                border: none;
                outline: none;

                .el-textarea__inner {
                  font-size: 14px;
                  color: #444443;

                  &,
                  &:focus {
                    border: none;
                  }
                }
              }
            }
          }

          .select-city {
            &.active-one {
              &::v-deep {
                .distpicker-address-wrapper {
                  label:nth-child(1) select {
                    color: $color;
                  }
                }
              }
            }

            &.active-two {
              &::v-deep {
                .distpicker-address-wrapper {
                  label:nth-child(2) select {
                    color: $color;
                  }
                }
              }
            }

            &.active-three {
              &::v-deep {
                .distpicker-address-wrapper {
                  label:nth-child(3) select {
                    color: $color;
                  }
                }
              }
            }

            &::v-deep {
              .distpicker-address-wrapper select {
                padding: 0;
                height: $height;
                border: 1px solid #787878;
                // border: 1px solid #DCDFE6;
                border-radius: 4px;
                outline: none;
                font-size: $font-size;
                // color: $color;
                color: #c0c4d6;
                margin-right: 16px;
                box-sizing: border-box;
                padding: 0 10px;

                &[disabled] {
                  background-color: #F5F7FA;
                }

                option {
                  color: $color;
                }
              }
            }
          }
        }
      }
    }

    .fm-rest {
      .fm-confirm {
        width: 200px;
        margin: 60px auto 20px;

        .fake-btn {
          width: 100%;
          font-size: 14px;
          color: #fff;
          border: 1px solid $common-number1-color;
          background: $common-number1-color;
          outline: none;

          &.el-button:hover,
          &.el-button:focus,
          &.el-button:active {
            color: none;
            border-color: none;
            background-color: none;
          }
        }
      }

      .fm-tips {
        text-align: center;
        font-size: 14px;
        color: #787878;
      }
    }

    .upload-module {
      display: flex;

      .upload-main {
        margin-right: 16px;

        &::v-deep {
          .el-upload--picture-card {
            border: none;
          }
        }

        &.hide-upload {
          &::v-deep .el-upload {
            display: none;
          }
        }

        &.identity-card {
          &::v-deep {
            .el-upload--picture-card {
              width: 192px;
              height: 162px;
              outline: none;
            }

            .el-upload-list--picture-card .el-upload-list__item {
              width: 192px;
              height: 162px;
            }
          }

          .befoer-box {
            width: 192px;
            height: 162px;
            line-height: 1.5;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .bb-cover {
              width: 150px;
              height: 100px;
              margin: 0 auto;
            }

            .bb-text {
              margin-top: 4px;
              font-size: 12px;
              color: #444343;

              .diff-style {
                color: #E62129;
              }
            }
          }
        }

        .default-befoer,
        .befoer-box {
          border-radius: 4px;
          overflow: hidden;
          border: 1px dashed transparent;
          background: #fff;

          &:hover {
            border-color: rgb(50, 184, 247);
          }
        }

        &::v-deep {
          .el-upload-list--picture-card .el-upload-list__item-thumbnail {
            object-fit: scale-down;
          }
        }

        .default-befoer {
          width: 100%;
          height: 100%;
          line-height: 1.5;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          &::v-deep {
            .el-upload--picture-card {
              border: none;
            }
          }

          .db-icon {
            display: inline-block;
            width: 36px;
            height: 36px;
            line-height: 36px;
            color: #AAAAAA;

            .iconfont {
              font-size: 36px;
            }
          }

          .db-text {
            box-sizing: border-box;
            padding: 16px 16px 0;
            font-size: 12px;
            color: #AAAAAA;
          }
        }
      }
    }

    .upload-field-box {
      display: flex;
      justify-content: center;

      .upload-module {
        margin-right: 30px;
        width: 120px;

        &::v-deep {

          .upload-demo,
          .el-upload {
            width: 100%;
          }
        }

        .fake-btn {
          width: 100%;
          height: 40px;
          font-size: 14px;
          color: #fff;
          border: 1px solid $common-number1-color;
          background: $common-number1-color;
          outline: none;

          .btn-main {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          &.el-button:hover,
          &.el-button:focus,
          &.el-button:active {
            color: none;
            border-color: none;
            background-color: none;
          }

          .iconfont {
            margin-right: 6px;
            font-size: 22px;
          }
        }
      }

      .upload-tips {
        display: flex;
        line-height: 1.7;
        font-size: 12px;
        color: #AAAAAA;

        .fake-span {
          max-width: 430px;
        }
      }
    }
  }

  .form-title-box {
    &::v-deep {
      .form-title {
        height: 50px;
        line-height: 50px;
      }

      .ft-descriptions {
        padding-left: 64px;
        // .diff {
        //   padding-right: 10px;
        // }
      }
    }
  }

</style>
